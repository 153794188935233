// 🌌 React
import React, { Suspense } from 'react';
// 🚧 React router
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// 🔐 Authentication
//import PrivateRoute from './context/Auth';
// 📌 Layouts components
import AdminLayout from './layouts/Admin';
// 📃 Pages
import Login from './layouts/Login';
import Register from './layouts/Register';
// 🎨️ App scss
import './assets/scss/docba-dashboard.scss';
import './App.scss';
// 🎯️ Font Awesome Icons
import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fal, fad, fab);
// Pseudo Element FontAwesome
dom.watch();
config.searchPseudoElements = true;

/**
 * App
 *
 * @augments {Component<Props, State>}
 */

const App = () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />

          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin/dashboard" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin/stores" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin/items" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin/users" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin/user-profile" render={(props) => <AdminLayout {...props} />} />

          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
