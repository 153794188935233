// 🌌 React
import React, { useState, useEffect, useRef } from 'react';
// 🚧 React router
import { NavLink } from 'react-router-dom';
// ⛽️ Bootstrap
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔐 Authentication
//import { UserContext } from '../context/UserContext';
// 🍪 Cookies
import Cookies from 'js-cookie';
// 🔗 API calls
import { loginUser } from '../utils/apiCallAuth';
// 🔨 Utils
import { setForEach, useFocus } from '../utils/utils';
// 📢 Sweet Alert 2 like toast massage
import { ToastTop } from '../components/SwalModalToast/SwalModalToast';
// ⚙️ Settings for Theme Color and Sidebar
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';

/**
 * Login Layout
 *
 * @augments {Component<Props, State>}
 */

const Login = (props, { history }) => {
  // * Translations
  const { t } = useTranslation();
  // * Authentication user state
  //const { user, setUser } = useContext(UserContext);
  let token = Cookies.get("SID");
  useEffect(() => {
    if (token) {
      props.history.push('/admin/dashboard');
    }
  }, [token, props.history]);

  const mainPanel = useRef();

  // * Focus input change active
  useEffect(() => {
    setForEach();
  }, []);

  // * Validating form state Bootstrap
  const [validated, setValidated] = useState(false);

  // * Email / Password
  const [credencials, setCredencials] = useState({
    email: '',
    password: '',
  });

  // * API CALLS for login user
  const handleResult = async (result) => {
    let { sid, user, error } = result;
    if (error) {
      ToastTop.fire({
        icon: 'error',
        title: t(error),
      });

      setValidated(false);
      return;
    }
    if (user && sid) {
      let inMinutes = new Date(new Date().getTime() + 45 * 60 * 1000); // Change 45 for other expire time in minutes
      Cookies.set('SID', sid, { expires: inMinutes });
      Cookies.set('ID', user.usr_id, { expires: inMinutes });
      //setUser(sid, history);
      ToastTop.fire({
        icon: 'success',
        title: t('swal.login.success'),
      });
      props.history.push('/admin/dashboard');
    }
  };

  // * HANDLE SUBMIT for login user
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();
      loginUser(credencials.email, credencials.password).then((data) => {
        handleResult(data);
      });
    }
  };

  // * HANDLE CHANGE input
  const handleOnchange = (e) => {
    setCredencials({
      ...credencials,
      [e.target.id]: e.target.value,
    });
  };

  // * USEREF & USEFOCUS CHANGE input
  const refemail = useRef();
  const focusedemail = useFocus(refemail);
  const refpass = useRef();
  const focusedpass = useFocus(refpass);

  return (
    <Container className="mt-5 pt-3 animate__animated animate__zoomIn" ref={mainPanel}>
      <Row className="d-flex justify-content-center">
        <Col xs={'11'} sm={'9'} md={'7'} lg={'5'} xl={'4'} className="d-flex justify-content-center px-0 py-3">
          <Card className="mt-4 mb-5 w-100">
            <Card.Body>
              <Card.Header className="er-header er-gradient rounded">
                <h3 className="my-3">
                  <FontAwesomeIcon icon={['fad', 'lock-alt']} size={'1x'} color={'#ffffff'} /> {t('login.title')}
                </h3>
              </Card.Header>
              <Form noValidate validated={validated} onSubmit={handleSubmit} className="mx-2 mt-5">
                <Form.Group controlId="email" className="er-form">
                  <Form.Control
                    required
                    type="email"
                    ref={refemail}
                    value={credencials.email}
                    name="email"
                    className="er-input-ml-w"
                    onChange={(event) => {
                      handleOnchange(event);
                    }}
                  />
                  <Form.Label className={`${focusedemail && 'active'}`}>{t('loginregister.email')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('loginregister.validmail')}</Form.Control.Feedback>
                  <Form.Text className={`${focusedemail && 'active'} text-muted er-input-ml-w`}>{t('loginregister.neversharemail')}</Form.Text>
                  <FontAwesomeIcon icon={['fad', 'envelope']} color={'#6c757d'} className={`prefix ${focusedemail && 'active'}`} />
                </Form.Group>

                <Form.Group controlId="password" className="er-form">
                  <Form.Control
                    required
                    type="password"
                    ref={refpass}
                    value={credencials.password}
                    name="password"
                    className="er-input-ml-w"
                    minLength={6}
                    onChange={(event) => {
                      handleOnchange(event);
                    }}
                  />
                  <Form.Label className={`${focusedpass && 'active'}`}>{t('loginregister.password')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('login.validpassword')}</Form.Control.Feedback>
                  <FontAwesomeIcon icon={['fad', 'key']} color={'#6c757d'} className={`prefix ${focusedpass && 'active'}`} />
                </Form.Group>

                <Form.Group controlId="formLoginRememberMe" className="my-4">
                  <Row>
                    <Col>
                      <small>
                        <Form.Check type="checkbox" label={t('login.rememberme')} custom />
                      </small>
                    </Col>
                    <Col>
                      <p className="d-flex justify-content-end er-pt-175">
                        <NavLink to="/forgotpassword" href="/forgotpassword" exact className="nav-link py-0 pr-0 pl-2 er-lineheight">
                          <small className="align-text-bottom">{t('login.forgotpassword')}</small>
                        </NavLink>
                      </p>
                    </Col>
                  </Row>
                </Form.Group>

                <Button block variant="primary" type="submit" className="rounded-pill text-uppercase btn-login">
                  {t('loginregister.login')}
                  <FontAwesomeIcon icon={['fad', 'paper-plane']} size={'1x'} className="ml-2" />
                </Button>

                {/* <Row className="d-flex justify-content-center">
                  <p className="text-center mt-3">
                    <small>{t('login.orsigninwith')}</small>
                  </p>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Button variant="outline-success" className="rounded-pill btn-social">
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} color={'#0098da'} />
                  </Button>
                  <Button variant="outline-success" className="rounded-pill btn-social">
                    <FontAwesomeIcon icon={['fab', 'google-plus-g']} color={'#0098da'} />
                  </Button>
                </Row> */}
              </Form>
              <hr />
              <p className="d-flex justify-content-end align-items-center">
                <small>{t('login.notmemberyet')}</small>
                <NavLink to="/register" href="/register" exact className="nav-link py-0 pr-0 pl-2 er-lineheight">
                  <small className="align-text-bottom">{t('loginregister.signup')}</small>
                </NavLink>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <FixedPlugin {...props} />
      </Row>
    </Container>
  );
};

export default Login;
